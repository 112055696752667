<template>
  <swiper-slide
    class="h-full w-full overflow-hidden no-scrollbar -ml-3"
    style="width: 95%"
  >
    <div
      class="h-full relative w-full rounded-lg module p-2 flex flex-col z-40"
      :class="{ 'ml-0': filteredOportunities, '-ml-84': !filteredOportunities }"
      style="transition: all 0.3s"
    >
      <loader :loading="loading" />

      <div class="h-10 flex flex-row justify-start items-center px-1">
        <span class="text-font-gray font-semibold mr-auto">SL</span>
        <i class="mdi mdi-filter-variant text-font-gray mr-1"></i>

        <div
          class="h-6 rounded-lg bg-box-80 flex flex-row justify-between items-center"
        >
          <multiselect label="name" v-model="sortB" :options="sortOptions" />
        </div>

        <div
          class="h-6 w-6 rounded-md flex flex-col justify-center items-center cursor-pointer mx-1"
          :class="{ 'bg-aux-15 text-aux': all, 'bg-filters': !all }"
          @click="toggleSlSelectedItemsAll()"
        >
          <span class="text-3xs">ALL</span>
        </div>
      </div>

      <div
        v-if="sl"
        class="flex-1 overflow-auto no-scrollbar"
        style="transition: all 0.3s"
      >
        <ProjectsSLMobile
          v-for="(el, index) in slSorted"
          :key="index"
          :row="el"
          @nextPage="nextPage"
        />
      </div>
      <div
        v-else-if="!loading && sl.length === 0"
        class="h-full w-full flex flex-col justify-center items-center"
      >
        <span class="module-title text-font-gray">No data in this section</span>
      </div>
    </div>
  </swiper-slide>
</template>

<script>
import { state, actions } from "@/store";
const ProjectsSLMobile = () =>
  import("@/components/Mobile/ProjectsManagement/ProjectsSLColumn/ProjectsSl");

export default {
  components: {
    ProjectsSLMobile,
  },
  name: "ProjectsSLColumn",
  props: ["sortBy"],
  data() {
    return {
      loading: false,
      menu: false,
      activeOption: [],
      filteredOportunities: true,
      sortOptions: this.$optionsFilterProjects,
      sortOrder: { name: "ASC", value: "asc" },
      sl: [],
      all: false,
      sortB: null,
    };
  },
  computed: {
    params() {
      return state.globalParams;
    },
    legendColors() {
      return state.slLegendColors;
    },
    slSelectedItems() {
      return state.slSelectedItems;
    },
    slSorted() {
      return _.orderBy(this.sl, [this.sortB.value], [this.sortB.direction]);
    },
  },
  watch: {
    params(n, o) {
      this.getSl();
    },
    sortB() {
      this.$emit("changeSortBy", this.sortB);
    },
  },
  mounted() {
    this.sortB = this.sortBy;
    this.preload();
    if (this.params) {
      this.getSl();
    }
  },
  methods: {
    preload() {
      actions.clearSlSelectedItems();
      this.$emit("loadingSl", true);
      this.loading = true;
    },
    getSl() {
      this.axios
        .get("projects_management/ner_sl", { params: this.params })
        .then((response) => {
          this.sl = response.data.data;
          this.$setColorsBySector(this.sl);
          this.loading = false;
          state.slSelectedItems = [];
          this.$emit("loadingSl", false);
        });
    },
    toggleSlSelectedItems(item) {
      actions.toggleSlSelectedItems(item);
    },
    nextPage(sl) {
      this.$emit("nextPage", sl);
    },
    toggleSlSelectedItemsAll() {
      this.all = !this.all;

      // for (let index = 0; index < Object.keys(this.legendColors).length; index++) {
      //     actions.toggleSlSelectedItems(Object.keys(this.legendColors)[index])
      // }
      if (!this.all) {
        for (
          let index = 0;
          index < Object.keys(this.legendColors).length;
          index++
        ) {
          state.slSelectedItems = state.slSelectedItems.filter(
            (item) => item !== Object.keys(this.legendColors)[index]
          );
        }
      } else {
        for (
          let index = 0;
          index < Object.keys(this.legendColors).length;
          index++
        ) {
          state.slSelectedItems.push(Object.keys(this.legendColors)[index]);
        }
      }

      if (this.all) {
        this.$emit("nextPage");
      }
    },
  },
};
</script>
